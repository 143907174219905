import * as React from "react"
import { Link } from "gatsby"

const Layout = ({ location, title, children }): JSX.Element => {
  // @ts-ignore
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath

  return (
    <div className="global-wrapper" data-is-root-path={isRootPath}>
      <header className="global-header">
        <Link className="header-link-home" to="/">
          <div style={{ display: "inline-flex", justifyContent: "flex-start" }}>
            <img src="/database-icon.svg" height="32" />
            <span style={{ paddingLeft: "0.5em" }}>{title}</span>
          </div>
        </Link>
      </header>
      <main>{children}</main>
      <footer>
        © {new Date().getFullYear()}, Built with
        {` `}
        <a href="https://www.gatsbyjs.com">Gatsby</a>
      </footer>
    </div>
  )
}

export default Layout
